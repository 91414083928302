


































































import { Vue, Component, Prop } from "vue-property-decorator";
import CustomerPaymentProductService from '@/services/CustomerPaymentProductService';
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import { MobilePaymentInfoProperties } from './MobilePaymentInfoView.vue';
import ExceptionUtil from '@/utilities/ExceptionUtil';

@Component({
    data: () => ({
        products: []
    })
})
export default class CustomerPaymentProductView extends Vue {
    @Prop() private properties: MobilePaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private detail: any;
    private customerPaymentProductService = new CustomerPaymentProductService();

    public get currency() {
        return this.properties.currency;
    }

    public formatPrice(v: any) {
        const symbol = this.currency?.symbol ?? "";
        return symbol + " " + (v ?? 0).toFixed(2);
    }

    public formatTotalAmount(v: any) {
        const symbol = this.currency?.symbol ?? "";
        return symbol + " " + (v ?? 0).toFixed(2);
    }

	public formatComment(order: any) {
		var m = order.comment.message;
		const r = order.remarks ?? "";

		if (r !== "") {
			const t = this.$t('text.remarks');
			m += `\n${t}: ${order.remarks}`;
		}
		return m;
	}

    public created() {
        this.load();
        this.properties.events.subscribe('load-products', this.load);
    }

    public destroyed() {
        this.properties.events.remove('load-products', this.load);
    }

    public async load() {
        try {
            if (this.detail.id) {
                const r = await this.customerPaymentProductService.current({
                    customerPaymentDetailId: this.detail.id
                });
                const products = r.data.customerPaymentProducts;
                this.$data.products = products.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.products = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
